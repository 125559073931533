<template>
  <v-content>
    <router-view />

  </v-content>
</template>
<script>
export default {
  components: {
    // 'footer':footer
  },
  mounted() {

  }
}
</script>
<link rel="preconnect" href="https://fonts.googleapis.com" />

<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />


<style>
@import '../../public/assets/css/cards.scss';
@import '../../public/assets/css/bootstrap.min.css';
@import '../../public/assets/css/font-awesome.css';
@import '../../public/assets/css/templatemo-breezed.css';
</style>